<template>
  <div>
    <div class="bg-header">

      <div class="card">
        <span style="color: #606266;font-size:15px">学生姓名：</span>
        <el-input v-model="form.student_keyword"></el-input>
      </div>

      <div style="float:right">
        <el-button type="primary"
                   @click="search"
                   icon="el-icon-search">查询</el-button>
        <el-button @click="reset">
          <img style="width:14px;height:14px"
               src="@/assets/reset.png">
          重置
        </el-button>
      </div>
    </div>

    <div class="bg-content">

      <div class="in-margin">
        <Table ref="TablesRef"
               :tableData="tableData" />

        <pagination ref="pagination"
                    :get-data="initData"
                    :now-page.sync="page"
                    :now-size.sync="size"
                    :total="totalElements" />
      </div>
    </div>
    <!-- -->
  </div>
</template>

<script>



export default {
  data () {
    return {
      title: '',
      type: '',
      page: 1,
      size: 10,
      totalElements: 0,
      tableData: [],
      tableLoading: false,
      form: { student_keyword: '' },
      tableColumns: [
        { prop: 'user_paper_id', align: 'left', label: '试卷ID', width: '100', sortable: true },
        { prop: 'truename', align: 'left', label: '学生名称', width: '' },
        { prop: 'status_desc', align: 'left', label: '批改状态', width: '' },
        { prop: 'user_score', align: 'left', label: '当前分数', width: '', sortable: true },
        // { prop: 'status', align: 'left', label: '是否已生成答题卡', width: '', isStatus: true },
        // {
        //   prop: 'd', align: 'left', label: '操作', width: '', type: 'text', btns: [
        //     { name: '查看试卷', class: 'text_link', event: 'toInfo' },
        //     { name: '制作答题卡', class: 'text_link', event: 'makeSheet' },
        //     { name: '答题卡列表', class: 'text_link', event: 'uploadSheet' },
        //     { name: '成绩查询', class: 'text_link', event: 'scoreQuery' },
        //   ]
        // },
      ]
    }
  },
  components: {
  },
  mounted () {
    // this.getGradeList()
    this.setTablesColums()
    // this.getSubjectList().then(() => this.initData())
  },
  activated () {
    // this.initData()
  },
  methods: {
    reset () {
      this.form.student_keyword = ''
      if (this.tableData && this.form.student_keyword) {
        this.tableData = []
      }

    },



    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },

    initData (page, limit) {
      if (page) {
        this.page = page
      }
      if (limit) {
        this.size = limit
      }
      this.$http({
        url: '/api/v1/henan/edu_paper_score',
        method: 'get',
        params: {
          page: this.page,
          limit: this.size,
          edu_paper_id: this.$route.query.edu_paper_id,
          // page,
          // limit,
          ...this.form
        }
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },

    search () {
      this.$refs.pagination.toFirstPage()
    },
    refresh () {
      this.$refs.pagination.shuaxin()
    }
  },


}
</script>

<style lang="scss" scoped>
.el-input {
  width: 18%;
}
.card {
  margin-left: 66px;
  display: inline;
}
.el-button--primary.is-plain {
  color: #3f9eff;
  background: #ecf5ff;
  border-color: #b3d8ff;
}
.el-button--primary:focus,
.el-button--primary:hover {
  background: #3f9eff;
  border-color: #3f9eff;
  color: #fff;
}
</style>